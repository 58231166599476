import { __ } from 'react-i18n';
import {
  isCompanyOrSchool,
  isSchool,
  isTeacher,
  canShowCustomerProducts,
} from './utilities';

export enum AttribTypes {
  withImage = 'sobrazkom',
  range = 'rozsah',
  default = 'zakladny',
}

export enum UrlMapTypes {
  product = 'product',
  category = 'category',
  CategoryGroup = 'category_group',
  subcategory = 'WITH_SUBCATEGORIES',
  Sitemap = 'sitemap',
  SitemapWithContent = 'sitemap_with_content',
  Shop = 'shop',
  redirect = 'redirect',
  brand = 'brand',
  school = 'school',
  productSale = 'product_sale',
}

export enum Store {
  CHEMAT = 'chemat',
  LAMITEC = 'lamitec',
}

export const BANNER_HOMEPAGE = 'HOMEPAGE';
export const BANNER_SCHOOL = 'SCHOOL';

export const NEWSLETTER_TYPE_SCHOOL = 'school';

export const FURNITURE_CATEGORY_GROUP_UNIQID = 'FURNITURE_SCHOOL';
export const SCHOOL_ARTICLES_UNIQID = 'SCHOOL_ARTICLES';
export const HOMEPAGE_UNIQID = 'HOMEPAGE';
export const SETS_UNIQID = 'SETS';

export const SCHOOL_ARTICLES_PER_PAGE = 6;
export const PRODUCTS_PER_PAGE = 20;
export const WEBS_PER_PAGE = 12;

export type BreadcrumbsItems = Array<{
  name: string;
  url: string;
  link?: string;
}>;

export const TITLE_ACCOUNT_SETS = __('Zoznamy pomôcok Skvelko');
export const TITLE_ACCOUNT_SETS_MANAGEMENT = __('Správa zoznamov');
export const TITLE_ACCOUNT_PRODUCTS_FOR_SETS = __('Produkty pre zoznamy');
export const TITLE_ACCOUNT_BULK_ORDER = __('Hromadná objednávka');

export const TITLE_SKVELKOBOX = __('Skvelko-box na webstránku školy');
export const TITLE_SKVELKOBOX_BENEFITS = __('Výhody');
export const TITLE_SKVELKOBOX_INSTRUCTIONS = __('Návod na použitie');

export const TITLE_SEARCH_ESHOP = __('Eshop');
export const TITLE_SEARCH_SHOOL_ESHOP = __('Pre školy');
export const TITLE_SEARCH_WEB = __('Web Daffer');

export const ANCHOR_FORM = 'formular';

export const ANCHOR_UNIVERSAL_SETS = 'univerzalne-sady';
export const URL_SETS = '/sady';
export const URL_SET = '/sada';
export const URL_SET_NEW = '/my-account/set/new';
export const URL_SET_NEW_CHOOSE = '/my-account/set/newChoose';

export const URL_SET_EDIT_PRODUCTS = '/my-account/set/edit-products';
export const URL_REQUEST_PRODUCT_PRICE = '/my-account/request-product-price';

export const URL_REGISTRATION_RETAIL = '/registracia';
export const URL_REGISTRATION_COMPANY = '/registracia-firma';
export const URL_REGISTRATION_SCHOOL = '/registracia-skola';

export const URL_LOGIN = '/prihlasenie';

export const URL_ACCOUNT_SETS = '/my-account/sets';
export const URL_ACCOUNT_PRODUCTS_FOR_SETS = '/my-account/products-for-sets';
export const URL_ACCOUNT_BULK_ORDER = '/my-account/bulk-order';

export const URL_REGISTRATION_COMPLETE_RETAIL = '/registracia-dokoncena';
export const URL_REGISTRATION_COMPLETE_COMPANY = '/registracia-dokoncena-firma';
export const URL_REGISTRATION_COMPLETE_SCHOOL = '/registracia-dokoncena-skola';

export const URL_CREDIT = '/vernostne-body';
export const URL_VIDEO_TUTORIALS = 'my-account/videonavody';

export const URL_SKVELKOBOX_BENEFITS = '/skvelkobox-vyhody';
export const URL_SKVELKOBOX_INSTRUCTIONS = '/skvelkobox-navod';

export const URL_SEARCH = '/vyhladavanie';
export const URL_SCHOOL_ARTICLES =
  '/pre-skolske-zariadenia/aktuality-pre-skoly';

export const URL_FOR_SCHOOLS = '/pre-skolske-zariadenia';

export const URLS_CART = [
  '/kosik',
  '/doprava-a-platba',
  '/udaje-pre-dorucenie',
];

export const URL_TYPES_PRODUCT = [UrlMapTypes.product, UrlMapTypes.productSale];

export const URL_TYPES_CATEGORY = [
  ...URL_TYPES_PRODUCT,
  UrlMapTypes.category,
  UrlMapTypes.CategoryGroup,
  UrlMapTypes.subcategory,
  UrlMapTypes.brand,
];

export const SIMPLE_TEMPLATE_URLS = [
  URL_SET_EDIT_PRODUCTS,
  URL_REQUEST_PRODUCT_PRICE,
  URL_SET_NEW_CHOOSE,
  ...URLS_CART,
];

export const SKVELKO_COMPETITION_ARTICLE_ID = 53330;

export interface MenuItem {
  name: string;
  url: string;
  highlighted: boolean;
}

export type MenuItems = MenuItem[];

export const getLoggedInMenu = (user: any): MenuItems => {
  let menu: MenuItems = [
    {
      name: __('Nástenka'),
      url: '/my-account',
      highlighted: false,
    },
  ];

  if (isSchool(user)) {
    menu = [
      ...menu,
      {
        name: TITLE_ACCOUNT_SETS,
        url: '/my-account/sets',
        highlighted: false,
      },
      {
        name: TITLE_SKVELKOBOX,
        url: URL_SKVELKOBOX_BENEFITS,
        highlighted: false,
      },
    ];
  }

  if (isSchool(user) && !isTeacher(user)) {
    menu = [
      ...menu,
      {
        name: __('Videonávody'),
        url: URL_VIDEO_TUTORIALS,
        highlighted: false,
      },
      {
        name: __('Vernostné body'),
        url: URL_CREDIT,
        highlighted: false,
      },
    ];
  }

  if (isCompanyOrSchool(user) && !isTeacher(user)) {
    menu = [
      ...menu,
      {
        name: __('Používatelia účtu'),
        url: '/my-account/renumeration',
        highlighted: false,
      },
    ];
  }

  menu = [
    ...menu,
    {
      name: __('História objednávok'),
      url: '/my-account/my-orders',
      highlighted: false,
    },
    {
      name: __('Obľúbené produkty'),
      url: '/my-account/favorite-products',
      highlighted: false,
    },
    {
      name: __('Najčastejšie nakupované produkty'),
      url: '/my-account/most-frequently-purchased-products',
      highlighted: false,
    },
  ];

  if (canShowCustomerProducts(user)) {
    menu = [
      ...menu,
      {
        name: __('Produkty za dohodnuté ceny'),
        url: '/my-account/products-customer',
        highlighted: false,
      },
    ];
  }

  menu = [
    ...menu,
    {
      name: __('Nastavenia'),
      url: '/my-account/settings',
      highlighted: false,
    },
    {
      name: __('Odhlásiť sa'),
      url: '/logout',
      highlighted: true,
    },
  ];

  return menu;
};

export const getNotLoggedInMenu = (): MenuItems => {
  return [
    {
      name: 'Prihlásenie',
      url: URL_LOGIN,
      highlighted: false,
    },
    {
      name: 'Registrácia',
      url: URL_REGISTRATION_RETAIL,
      highlighted: false,
    },
  ];
};

export type OtherText = {
  name: string;
  body: string;
};
